<template>
  <div v-editable="blok" class="teaser">
    {{ blok.headline }}
    <StoryblokImage v-if="image.filename" :image="image" :width="1400" :height="800" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  blok: {
    headline: string
    images: {
      filename: string
      focus: string
      meta_data: {
        alt: string
        title: string
        copyright: string
        source: string
      }
    }[]
  }
}>()

const image = computed(() => {
  return props.blok.images[0]
})
</script>

<style lang="scss">
.teaser {
  img {
    width: 100%;
  }
}
</style>
